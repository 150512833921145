<template>
  <v-content>
    <v-container grid-list-lg fluid>
      <v-layout wrap align-center justify-center fill-height>
        <v-flex xs12 lg12 xl12>
          <app-title :title="`${'id'.includes(item) ? 'Editar' : 'Nuevo'}`" :subtitle="'---'"></app-title>
        </v-flex>
        <v-flex xs12 lg8 xl8>
          <v-card>
            <app-title-card :title="'Rellene el formulario'" :subtitle="'Todos los campos son obligatorios'" />
            <v-divider></v-divider>
            <v-card-text>
              <v-layout wrap align-center justify-center fill-height>
                <v-flex xs12 sm12>
                  <v-text-field
                    v-model="item.name"
                    hint="Recuerde solo usar minisculas"
                    label="Nombre de la etiqueta"
                    placeholder=""
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12>
                  <v-text-field v-model="item.title" label="SEO - Titulo" placeholder=""></v-text-field>
                  <v-textarea v-model="item.description" :label="`SEO - Descripcion`"> </v-textarea>
                  <p>
                    Usar posibilidades
                    <a
                      href="#ia"
                      @click="
                        $store.dispatch('changeIa', {
                          active: true,
                          text: `Generar meta-title y meta-description con la siguiente frase '${item.name}'`
                        })
                      "
                    >
                      generadas con ia
                    </a>
                  </p>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :loading="loading" :disabled="loading" color="accent" flat @click="saveItem"> Listo </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex v-if="item.id" xs12 lg8 xl8>
          <v-card class="elevation-2">
            <v-toolbar class="elevation-0">
              <div class="mb-1 title font-weight-regular">{{ item.count }} productos en total</div>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchText"
                hide-details
                append-icon="search"
                placeholder="Buscar por nombre o codigo"
                flat
                solo
                single-line
                right
                @keyup.native="searchInputHandle"
              ></v-text-field>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="headers"
              :items="products"
              :pagination.sync="pagination"
              :rows-per-page-items="rowsPerPage"
              :loading="loading"
              class="elevation-0"
            >
              <template slot="items" slot-scope="props">
                <td width="100">
                  <router-link
                    style="text-decoration: none; position: relative;"
                    target="_blank"
                    :to="{ name: 'productCategory', params: { id: props.item.id } }"
                  >
                    <v-img
                      :src="props.item.img.url_small"
                      :lazy-src="`https://picsum.photos/10/6`"
                      class="lighten-2"
                      width="100"
                    />
                    <v-chip style="margin: 5px 0" label color="info" text-color="white" small>
                      P{{ props.item.id }}
                    </v-chip>
                    <span
                      v-if="props.item.status_stock"
                      class="ml-1 v-chip v-chip--label theme--light success white--text"
                      style="padding: 4px 10px;border-radius: 20px;position: absolute;left: 0;top: 0;"
                    >
                      {{ props.item.quantity_stock }}
                    </span>
                  </router-link>
                </td>
                <td class="text-xs-left">
                  <v-btn
                    style="margin: 0"
                    color="accent"
                    depressed
                    :title="props.item.name"
                    target="_blank"
                    :to="{ name: 'productCategory', params: { id: props.item.id } }"
                  >
                    <span v-html="shortName(props.item.name)"></span>
                  </v-btn>
                </td>
                <td class="text-xs-left">
                  <v-chip
                    v-if="props.item.status"
                    color="success"
                    text-color="white"
                    label
                    @click="changeStatus(props.item.id, props.item.status, 'estado', props.item)"
                  >
                    <v-icon left>check</v-icon> Activo
                    <span v-if="props.item.status_stock" class="ml-1">
                      ({{ props.item.quantity_stock }} disponibles)</span
                    >
                  </v-chip>
                  <v-chip v-else color="error" text-color="white" label @click="openStatusStock(props.item)">
                    <v-icon left>clear</v-icon> Inactivo
                  </v-chip>
                </td>
                <td class="text-xs-left">
                  <v-btn small depressed color="text" @click="removeTagProduct(props.item.id)">Quitar</v-btn>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-btn v-if="$route.params.id !== 'nuevo'" fixed dark fab bottom right color="accent" @click="addProducts()">
      <v-icon> add </v-icon>
    </v-btn>
    <v-dialog v-model="addProduct" max-width="400px" persistent>
      <v-card>
        <TitleDialog
          :title="'Añadir un producto'"
          close
          :close-action="
            () => {
              addProduct = false
            }
          "
        ></TitleDialog>
        <v-container class="pa-3" grid-list-lg>
          <v-layout row wrap>
            <v-flex xs12>
              <v-autocomplete
                v-model="product"
                :items="productsList"
                :item-text="
                  item => {
                    return `${item.name} - P${item.id}`
                  }
                "
                flat
                cache-items
                hide-no-data
                hide-details
                label="Seleccione un producto"
                return-object
              >
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="back" flat @click.native="addProduct = false"> Volver </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click.native="addTagProudct()"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
import { GET_TAGS, GET_PRODUCTS_ALL, GET_SEARCH_PRODUCTS } from '../../config'
import appTitle from '../useful/title.vue'
import appTitleCard from '../useful/titleCard.vue'
import TitleDialog from '../useful/titleDialog.vue'

export default {
  name: 'TagsEdit',
  components: { appTitle, appTitleCard, TitleDialog },
  data() {
    return {
      item: {},
      name: '',
      loading: false,
      addProduct: false,
      product: null,
      productsList: [],
      productsAll: [],
      products: [],
      searchText: '',
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
      headers: [
        {
          text: 'Foto',
          align: 'left',
          sortable: false,
          value: 'photo'
        },
        {
          text: 'Nombre',
          align: 'left',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Estado',
          align: 'left',
          sortable: false,
          value: 'status'
        }
      ]
    }
  },
  watch: {
    $route() {
      this.getItems()
    }
  },
  mounted() {
    setTimeout(() => {
      this.getItems()
    }, 500)
  },
  methods: {
    async getItems() {
      if (this.$route.params.id !== 'nuevo') {
        const res = await this.$http({
          method: 'GET',
          url: GET_TAGS,
          params: {
            id: this.$route.params.id
          }
        })
        if (Object.keys(res.data).length > 0) {
          this.item = res.data[0]
          this.getProducts(this.item.id)
        }
      }
    },
    async getProducts(id) {
      const res = await this.$http({
        method: 'GET',
        url: GET_PRODUCTS_ALL,
        params: {
          on: true,
          off: true,
          hidden: false,
          tagID: id
        }
      })
      this.productsAll = res.data
      this.products = res.data
      console.log(res.data)
    },
    async saveItem() {
      this.loading = true
      try {
        const res = await this.$http.post(GET_TAGS, { item: this.item })
        const error = res.data.error
        if (error === 1) {
          this.$store.dispatch('changeSnack', { active: true, text: res.data.msj })
          this.loading = false
          return
        }
        setTimeout(() => {
          this.loading = false
          this.$store.dispatch('changeSnack', { active: true, text: res.data.msj })
          this.$router.push({ name: 'tagsId', params: { id: res.data.id } })
        }, 500)
      } catch (e) {
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    },
    async addProducts() {
      try {
        this.addProduct = true
        const res = await this.$http.get(GET_SEARCH_PRODUCTS, {
          params: {
            dateShipping: new Date().toISOString().substr(0, 10),
            stateId: 1,
            schedule: 0,
            actives: 1
          }
        })
        this.productsList = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
      }
    },
    async addTagProudct() {
      this.loading = true
      if (!this.product) {
        return
      }
      try {
        const tag_id = this.$route.params.id
        const res = await this.$http.put(`${GET_TAGS}/${tag_id}/${this.product.id}`)
        const error = res.data.error
        this.$store.dispatch('changeSnack', { active: true, text: res.data.msj })
        if (error === 1) {
          this.loading = false
          return
        }
        this.getItems()
        this.addProduct = false
        this.loading = false
      } catch (e) {
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    },
    async removeTagProduct(product_id) {
      this.loading = true
      try {
        const tag_id = this.$route.params.id
        const res = await this.$http.delete(`${GET_TAGS}/${tag_id}/${product_id}`)
        this.$store.dispatch('changeSnack', { active: true, text: res.data.msj })
        this.getItems()
        this.loading = false
      } catch (e) {
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    },
    shortName(s) {
      const count = 40
      return s.slice(0, count) + (s.length > count ? '...' : '')
    }
  }
}
</script>
